<template>
  <Layout>
    <PageHeader :items="items" />
    <div class="card" style="margin-top: -30px;">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div class="col-md-2">
            <label>Country</label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getAllStates()"
            ></multiselect>
          </div>
          <!-- Country List END -->
          <!-- State List -->
          <div class="col-md-2">
            <label>State</label>
            <multiselect
              v-model="state"
              :options="stateArr"
              :show-labels="false"
              label="state"
              track-by="state"
              @input="getAllCities()"
            ></multiselect>
          </div>
          <!-- State List END -->
          <!-- Cities List -->
          <div class="col-md-2">
            <label>City</label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="getAllCompanies()"
            ></multiselect>
          </div>
          <!-- Cities List END -->
          <!-- Company List -->
          <div class="col-md-2">
            <label>Company</label>
            <multiselect
              v-model="companyID"
              :options="companyArr"
              :show-labels="false"
              label="companyName"
              track-by="companyID"
              @input="getRestaurantBrandsByCompanyID()"
            ></multiselect>
          </div>
          <!-- Compnay List END -->
          <!-- Brand List -->
          <div class="col-md-2">
            <label>Restaurant Brand</label>
            <multiselect
              v-model="brandData"
              :options="brandArr"
              :show-labels="false"
              label="name"
              track-by="restID"
              @input="readRestaurantBranchData()"
            ></multiselect>
          </div>
          <!-- Brand List END -->
          <!-- Branch List -->
          <div class="col-md-2">
            <label>Restaurant Branch </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              :show-labels="false"
              label="branchName"
              track-by="restBranchID"
              @input="onchangeBranch()"
            ></multiselect>
          </div>
          <!-- Branch List END -->
        </div>
        <div class="row inner mb-2 mt-1">
          <!-- Date range -->
          <div class="col-md-3">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              append-to-body
              confirm
              format="DD MMM Y"
              lang="en"
              range
              @input="onchangeBranch()"
            ></date-picker>
          </div>
          <!-- Date range END -->
          <!-- Filter Button -->
          <div class="col-md-4">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter</button
            >&nbsp;
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <div class="col-md-5 mt-4">
            <div class="row">
              <div class="col-md-12 mb-1">

                <button :disabled="generatingInvoice"
                        class="btn btn-themeYellow float-end"
                        @click="generateBulkInvoice()">
                  <i class="uil-invoice"></i> Generate Invoices
                </button>
                <div v-if="generatingInvoice"
                     class="text-center text-danger float-end m-1"
                     >
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </div>
              <div class="col-md-12">
                 <span class="text-muted mt-8">
                   <small>
                     Note: To generate bulk invoices, please select Restaurant Branch and Date Range.
                   </small>
                 </span>
              </div>
            </div>
          </div>
          <!-- Filter End -->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select
                      v-model="perPage"
                      :options="pageOptions"
                      size="sm"
                      style="margin-left: 5px; margin-right: 5px"
                    ></b-form-select>
                    &nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      class="form-control form-control-sm ms-2"
                      placeholder="Search..."
                      type="search"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :busy="isTableBusy"
                :current-page="currentPage"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                :items="tableData"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                bordered
                foot-clone
                hover
                outlined
                responsive
                striped
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template v-slot:cell(Action)="data">
                  <a
                    :href="$ApiUrl + 'invoice/' + data.item.id + '/0'"
                    target="_blank"
                  >
                    <i
                      v-if="data.item.invoiceLink"
                      class="uil-info-circle"
                      style="font-size: 19px"
                      title="Download Invoice"
                    ></i>
                  </a>
                </template>
                <template v-slot:cell(orderID)="data">
                  <span
                    style="cursor: pointer; color: #2069f1"
                    @click="viewOrderDetails(data.item.oid)"
                  >
                    {{ data.item.orderID }}
                  </span>
                </template>
                <template v-slot:cell(itemTotal)="data">
                  <div>{{ $func.formatPrice(data.item.itemTotal) }}</div>
                </template>
                <template v-slot:cell(cgst)="data">
                  <div>{{ $func.formatPrice(data.item.cgst) }}</div>
                </template>
                <template v-slot:cell(sgst)="data">
                  <div>{{ $func.formatPrice(data.item.sgst) }}</div>
                </template>
                <template v-slot:cell(total)="data">
                  <div>{{ $func.formatPrice(data.item.total) }}</div>
                </template>
                <template v-slot:cell(employeeName)="data">
                  <span> {{ data.item.userName }}</span>
                </template>
                <template v-slot:cell(restaurant)="data">
                  <span> {{ data.item.restBranchName }}</span>
                </template>
                <template v-slot:foot(action)>
                  <span>Total</span>
                </template>
                <template v-slot:foot(created)>
                  <span></span>
                </template>
                <template v-slot:foot(invoiceNo)>
                  <span></span>
                </template>
                <template v-slot:foot(orderID)>
                  <span></span>
                </template>
                <template v-slot:foot(itemTotal)>
                  <span>{{ $func.formatPrice(totalItemTotal) }}</span>
                </template>
                <template v-slot:foot(cgst)>
                  <span>{{ $func.formatPrice(totalCGST) }}</span>
                </template>
                <template v-slot:foot(sgst)>
                  <span>{{ $func.formatPrice(totalSGST) }}</span>
                </template>
                <template v-slot:foot(total)>
                  <span>{{ $func.formatPrice(totalTotal) }}</span>
                </template>
                <template v-slot:foot(employeeName)>
                  <span></span>
                </template>
                <template v-slot:foot(restName)>
                  <span></span>
                </template>
                <template v-slot:foot(restCode)>
                  <span></span>
                </template>
                <template v-slot:foot(branchName)>
                  <span></span>
                </template>
                <template v-slot:foot(branchCity)>
                  <span></span>
                </template>
                <template v-slot:foot(branchState)>
                  <span></span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="rows"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modals -->
    <OrderReciept
      :orderDetails="this.orderDetails"
      :orderStatusArr="this.orderStatusArr"
      :orderedItemArr="this.orderedItemArr"
      :invoice="this.invoice"
    ></OrderReciept>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import OrderReciept from "@/components/orderReciept";

/**
 * Advanced-table component
 */

export default {
  page: {
    title: "Invoice Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, Multiselect, OrderReciept },
  data() {
    return {
      isTableBusy: false,
      tableData: [],
      cardData: [],
      generatingInvoice:false,
      title: "Invoice Report",
      items: [
        {
          text: "View",
        },
        {
          text: "Invoice Report",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      daterange: [],
      orderData: [],
      orderDetails: [],
      invoice:[],
      branches: [],
      branchID: "",
      restBranchID: this.$storageData.profile.restBranchID,
      cityArr: [],
      city: "",
      state: "",
      stateArr: [],
      countryArr: [],
      country: "",
      brandArr: [],
      brandData: "",
      companyArr: [],
      companyID: 0,
      orderedItemArr: [],
      orderItemAddonArr: [],
      orderStatusArr: [],
      orderStatusOptions: [
        { statusID: "2", statusName: "Pending" },
        { statusID: "3", statusName: "Accepted" },
        { statusID: "4", statusName: "Preparing" },
        { statusID: "5", statusName: "Prepared" },
        { statusID: "6", statusName: "Dispatched" },
        { statusID: "7", statusName: "Delivered" },
        { statusID: "8", statusName: "Cancelled" },
      ],
      paymentType: "",
      paymentTypeArr: [
        "Free meal",
        "Wallet",
        "Subsidy + Wallet",
        "Pre-buy voucher",
        "Subsidy",
      ],
      orderTypeArr: [
        "Cafeteria",
        "Near by Restuarant",
        "Free Meal",
        "Team Meal",
      ],
      orderType: "",
      orderStatus: "",
      fields: [
        {
          key: "Action",
          sortable: false,
        },
        {
          key: "created",
          label: "Date",
          sortable: true,
        },
        {
          key: "invoiceNo",
          sortable: true,
        },
        {
          key: "orderID",
          sortable: true,
        },
        {
          key: "itemTotal",
          label: "Item Total",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "cgst",
          label: "CGST",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "sgst",
          label: "SGST",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "total",
          label: "Total",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "employeeName",
          label: "Customer Name",
          sortable: true,
        },
        {
          key: "restName",
          label: "Restaurant",
          sortable: true,
        },
        {
          key: "restCode",
          label: "Rest Code",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "branchName",
          label: "Location",
          sortable: true,
        },
        {
          key: "branchCity",
          label: "City",
          sortable: true,
        },
        {
          key: "branchState",
          label: "State",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    //if(this.$storageData.profile.empTypeID==2 || this.$storageData.profile.empTypeID==11){
    this.getAllCountry();

  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    readOrdersData() {
      this.isTableBusy = true;
      this.axios
          .post(this.$loggedRole+"/viewInvoicesReport", {
            restID: this.brandData.restID,
            restBranchID: this.branchID.restBranchID,
            country: this.country ? this.country : "",
            state: this.state ? this.state : "",
            city: this.city != "" && this.branchID != null ? this.city : "",
            // restID : (this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID,
            // restBranchID : (this.branchID!="" && this.branchID!= null) ? this.branchID.restBranchID : this.$storageData.profile.restBranchID,
            daterange: this.daterange,
            paymentType: this.paymentType,
            orderType: this.orderType,
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID,
            viewData: this.tableView,
          })
          .then((response) => {
            this.isTableBusy = false;
            //Then injecting the result to datatable parameters.

            this.totalItemTotal = response.data.totalTtemTotal;
            this.totalCGST = response.data.totalCGST;
            this.totalSGST = response.data.totalSGST;
            this.totalTotal = response.data.totalTotal;
            this.tableData = response.data.data;
          });
    },

    dateFilter() {
      // alert(this.daterange);
      //this.readOrdersData();
    },

    viewOrderDetails(orderID) {
      // alert(orderID);
      this.$root.$emit("bv::show::modal", "modal-lg-orderDetails");
      this.axios
          .post(this.$loggedRole+"/getOrderDetailsByID", { orderID: orderID })
          .then((result) => {
            this.orderDetails = result.data.data;
            this.orderStatusArr = result.data.data.orderStatus;
            this.orderedItemArr = result.data.data.orderedItemArr;
            this.invoice = result.data.invoice;
          });
    },

    // Get All Country List
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios.post(this.$loggedRole+"/getAllCountry").then((response) => {
        this.countryArr = response.data.data;
      });
    },

    getRestaurantCountry() {
      this.axios
          .post(this.$loggedRole+"/getRestaurantCountry", {
            restID: this.$storageData.profile.restID,
          })
          .then((response) => {
            this.countryArr = response.data.data;
            this.country = this.countryArr ? this.countryArr[0] : "";
            this.getAllCompanies();
          });
    },

    // Get All States List
    getAllStates() {
      this.state = "";
      this.stateArr = [];
      this.axios
          .get(this.$loggedRole+"/getAllState", {
            country: this.country ? this.country.country : "",
          })
          .then((response) => {
            this.stateArr = response.data.data;
          });
    },

    // Get All Cities List
    getAllCities() {
      this.city = "";
      this.cityArr = [];
      this.axios
          .post(this.$loggedRole+"/getAllCities", {
            state: this.state ? this.state.state : "",
          })
          .then((response) => {
            this.cityArr = response.data.data;
          });
    },

    // Get All Restaurant Companies List
    getAllCompanies() {
      this.companyID = "";
      this.companyArr = [];
      var companyID = 0;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
          .post(this.$loggedRole+"/getAllCompanies", {
            companyID: companyID,
            country: this.country ? this.country.country : "",
            city: this.city ? this.city.city : "",
          })
          .then((response) => {
            this.companyArr = response.data.data;
            if (this.$storageData.profile.empTypeID == 11) {
              this.companyID = this.companyArr ? this.companyArr[0] : "";
            }
          });
    },

    // Get All Reastaurant Brands List
    getRestaurantBrandsByCompanyID() {
      var companyID = this.companyID;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
          .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {
            companyID: companyID,
          })
          .then((response) => {
            this.brandArr = response.data.data;
          });
    },

    getRestaurantBrands() {
      this.axios
          .post(this.$loggedRole+"/getRestaurantList", {
            restID: this.$storageData.profile.restID,
            restBranchID: this.$storageData.profile.restBranchID,
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID,
            companyID: this.companyID ? this.companyID.companyID : 0,
          })
          .then((result) => {
            this.brandArr = result.data.data;
          });
    },

    getRestaurantCities() {
      var restID = this.brandData.restID;
      if (this.$storageData.profile.empTypeID == 11) {
        restID = this.$storageData.profile.restID;
      }
      this.axios
          .post(this.$loggedRole+"/getRestaurantCities", {
            restID: restID,
            country: this.country ? this.country.country : "",
          })
          .then((response) => {
            this.cityArr = response.data.data;
            this.city = this.cityArr ? this.cityArr[0] : "";
            if (this.$storageData.profile.empTypeID == 11) {
              this.getRestaurantBrandsByCompanyID();
            } else {
              this.readRestaurantBranchData();
            }
          });
    },

    readRestaurantBranchData() {
      var restBranchID = 0;
      if (this.$storageData.profile.empTypeID == 6) {
        restBranchID = this.$storageData.profile.restBranchID;
      }

      this.axios
          .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
            restID:
                this.brandData != ""
                    ? this.brandData.restID
                    : this.$storageData.profile.restID,
            restBranchID: restBranchID,
            empID: this.$storageData.profile.pid,
            empTypeID: this.$storageData.profile.empTypeID,
            city: this.city ? this.city.city : "",
            country: this.country ? this.country.country : "",
            companyID: this.companyID ? this.companyID.companyID : 0,
          })
          .then((response) => {
            this.branches = response.data.data;
            // this.branchID = (this.branches) ? this.branches[0] :"";
            // this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
          });
    },

    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
    },

    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.readOrdersData();
    },
    generateBulkInvoice(){
      if(this.branchID.length==0 || this.branchID.restBranchID=="undefined")
      {
        this.$swal({
          text: "Please select restaurant branch.",
          icon: "error"
        });

      }
      else if(this.daterange.length==0 || this.daterange.length=="undefined"){
        this.$swal({
          text: "Please select date range.",
          icon: "error"
        });

      }
      else{
        this.generatingInvoice=true;
        this.axios
            .post(this.$loggedRole+"/invoice/bulk-generate",
                {
                  restBranchID: this.branchID.restBranchID,
                  daterange: this.daterange,
                })
            .then((result) => {
              this.generatingInvoice=false;
              this.$swal({
                text: result.data.message,
                icon: "success"
              });

            })
            .catch((error)=>{
              this.generatingInvoice=false;
              this.$swal({
                text: error.response.data.message,
                icon: "error"
              });

            });
      }
    },
    clearFilter() {
      this.country = "";
      this.state = "";
      this.city = "";
      this.cityArr = [];
      this.companyID = "";
      this.brandData = "";
      this.branchID = "";
      this.branches = [];
      this.restBranchID = "";
      this.daterange = [];
    },
  },
  middleware: "authentication",
};
</script>
<style scoped>
.my-class {
  max-width: 10px !important;
}
</style>